/* Behavior - behaviors/dataTables.js */

$(function () {
    $('table').DataTable( {
        paging: false,
        searching: false,
        ordering:  false,
        "scrollX": true,
        "autoWidth": false
    } );
} );
