/* Behavior - behaviors/slide.js */

jQuery(document).ready(function($) {    
    var galleryThumbs = new Swiper('.gallery-thumbs', {
        autoplay: {
            delay: 6000,
            disableOnInteraction: false,
        },
        grabCursor: false,
        spaceBetween: 17,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,

      });
      var galleryTop = new Swiper('.gallery-top', {
        autoplay: {
            delay: 6000,
            disableOnInteraction: false,
        },
        grabCursor: true,
        spaceBetween: 30,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        navigation: {
          nextEl: '.swiper-nav-next',
          prevEl: '.swiper-nav-prev',
        },
        thumbs: {
          swiper: galleryThumbs
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
});