/* Behavior - behaviors/slide.js */

$(function () {
    var $slide = $('.box-slide .owl-carousel');

    if(!$slide.length) return

    $slide.owlCarousel({
        items: 1,
        autoplay: true,
        loop: false,
        navContainer: $('.box-slide .slide-navs-inner'),
        rewind: true,
        dots: false,
        nav: true,
        navElement: 'span',
        navText : ['<span aria-label="Ant."><i class="icon-arrow-left"></i></span>','<span aria-label="Próx."><i class="icon-arrow-right"></i></span>'],
        navSpeed: 500,
        dotsSpeed: 500,
        freeDrag: false,
        dragEndSpeed: 500,
        autoplaySpeed: 500,
        autoplayTimeout: 6000
    });

    var $slideNavs = $('.box-slide .slide-navs-inner.disabled');
    
    if(!$slideNavs.length) return
    $('.box-slide .slide-navs').addClass('disabled');
});