$(function() {
    var $backTop = $('.back-top');

    if(!$backTop.length) return
    
    $backTop.on('click', function(e) {
        
        e.preventDefault();

        $('html, body').animate({
            scrollTop: $('body').offset().top
        }, 600);
    });

    $(window).scroll(function() {
        if ($(window).scrollTop() > 300) {
            $backTop.addClass('show');
        } else {
            $backTop.removeClass('show');
        }
    });
});